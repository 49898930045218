import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/seo'
import Title from '../components/title'
import Header from '../components/header'
import Container from '../components/container'
import FeatureBlock from '../components/feature-block'
import Brands from '../components/brands'
import Layout from '../layout'
import Image from '../components/image'
import AnimatedArticle from '../components/animated-article'
import AnimatedAside from '../components/animated-aside'
import P from '../components/paragraph'

const BrandsPage = () => {
  const { brandsBottle } = useStaticQuery(graphql`
    query {
      brandsBottle: file(relativePath: { eq: "brands/bottle.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 740, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title='Brands'
        description={`
          Valorem Distribution works with an eclectic mix of niche, global
          brands representing them across markets in China, the Gulf, America,
          Europe and the UK.
        `}
      />

      <Header />

      <Container wide noPadding>
        <FeatureBlock>
          <AnimatedArticle onLoad css={`
            @media(min-width: ${({ theme }) => theme.breakpoint.large}) {
              margin-top: 50px
            }`}
          >
            <Title fancy>Brands</Title>
            <P>
              Valorem distribution Limited has a truly global perspective, with
              established distribution pathways around the globe and a
              specialised local knowledge that our clients can call upon at
              anytime. We are currently making waves as far afield as China,
              the Gulf, America, Europe and the UK, developing initiatives with
              beauty influencers to change perceptions and drive sales.
            </P>
          </AnimatedArticle>
          <AnimatedAside onLoad>
            <Image
              fluid={brandsBottle.childImageSharp.fluid}
              notch='bottomLeft'
              alt='VDL Brands'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container width={1200}>
        <Brands />
      </Container>

    </Layout>
  )
}

export default BrandsPage
